<template>
  <div class="container">
    <div v-if="client">
      <div class="row mb-3">
        <div class="col-auto my-auto">
          <h5 class="mb-0 client-tab-heading">Resources</h5>
        </div>
        <div class="col text-end">
          <div class="dropleft">
            <button
              class="btn btn-outline-dark btn-sm dropdown-toggle"
              type="button"
              id="sortByDropDown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Sort by
            </button>
            <div class="dropdown-menu" aria-labelledby="sortByDropDown">
              <a class="dropdown-item" @click="sortNotesBy('oldest-newest')"
                >Oldest to Newest</a
              >
              <a class="dropdown-item" @click="sortNotesBy('newset-oldest')"
                >Newest to Oldest</a
              >
            </div>
          </div>
        </div>
      </div>

      <!--  -->
      <hr class="my-3" />

      <p class="alert alert-primary">
        <i class="fa fa-info me-2"></i>
        Client Resources allows you to view and organise resources.
        <i
          ><small
            >Please note that editting resource names here will update your
            global resources.</small
          ></i
        >
      </p>
      <div class="card bg-light mb-3">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-auto my-2">
              <div class="form-inline flexbox-row">
                <input
                  type="text"
                  class="form-control me-1"
                  :class="submitted && !folderName ? 'is-invalid' : ''"
                  placeholder="Create a folder..."
                  v-model="folderName"
                  @keyup.enter="createFolder"
                />
                <button class="btn btn-outline-primary" @click="createFolder">
                  <i class="far fa-folder-plus"></i>
                </button>
              </div>
            </div>
            <div class="col-auto me-auto my-2">
              <div class="btn-group me-2">
                <button
                  class="btn"
                  @click="listView = !listView"
                  :class="!listView ? 'btn-primary' : 'btn-outline-primary'"
                >
                  <i class="far fa-th-large me-1"></i>
                  Grid
                </button>
                <button
                  class="btn"
                  @click="listView = !listView"
                  :class="listView ? 'btn-primary' : 'btn-outline-primary'"
                >
                  <i class="far fa-list me-1"></i>
                  List
                </button>
              </div>
              <div class="btn-group me-2">
                <button
                  class="btn btn-outline-primary"
                  @click="addResource()"
                  v-tooltip:bottom="'Share a resource with this client'"
                >
                  <i class="fas fa-plus me-2"></i>Add Resource
                </button>
              </div>
            </div>
            <div class="col-auto my-2">
              <button
                class="btn btn-outline-primary me-2"
                :class="sortDirection == 'asc' ? 'active' : ''"
                @click="sortDirection = 'asc'"
              >
                <i class="far fa-sort-size-up"></i>
              </button>
              <button
                class="btn btn-outline-primary"
                :class="sortDirection == 'desc' ? 'active' : ''"
                @click="sortDirection = 'desc'"
              >
                <i class="far fa-sort-size-down"></i>
              </button>
            </div>
            <div class="col-auto my-2">
              <div class="dropdown">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  id="sortingDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="far fa-sort me-2"></i>
                  <span class="text-capitalize">{{
                    sorting | formatLabel
                  }}</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="sortingDropdown">
                  <button
                    class="dropdown-item"
                    type="button"
                    @click="sorting = 'name'"
                  >
                    Name
                  </button>
                  <button
                    class="dropdown-item"
                    type="button"
                    @click="sorting = 'created_at'"
                  >
                    Date Created
                  </button>
                </div>
              </div>
            </div>
            <div class="col-auto my-2" v-if="folderOpen">
              <span class="me-2">Current folder: {{ folderOpen.name }}</span>
              <button
                class="btn btn-outline-primary"
                @click="upLevel()"
                id="parentDrop"
                @dragover.prevent
                @drop.stop="folderDrop($event)"
                @dragenter.stop="folderDragEnter($event)"
                @dragleave.stop="folderDragLeave($event)"
              >
                <i class="far fa-level-up-alt"></i>
              </button>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-5">
              <input
                type="text"
                v-model="search"
                class="form-control"
                autocomplete="offington"
                placeholder="Search by name or URL"
              />
            </div>
            <div class="col ms-0 ps-0">
              <button class="btn btn-sm btn-light" @click="clearSearch">
                <i class="far fa-times"></i>
              </button>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <grid-view
                v-if="!listView"
                :resources="currentResources"
                :folders="currentFolders"
                :loading="loading"
                @openFolder="openFolder"
                @renameResource="updateResource"
                @unshareResource="unshareResource"
                @unshareFolder="unshareFolder"
                @viewResource="viewResource"
                @refreshed="fetchClientResources"
              ></grid-view>
              <list-view
                v-else
                :resources="currentResources"
                :folders="currentFolders"
                :loading="loading"
                @openFolder="openFolder"
                @viewResource="viewResource"
                @renameResource="updateResource"
                @refreshed="fetchClientResources"
              ></list-view>
            </div>
          </div>
        </div>
      </div>
      <add-resource ref="addResource" :resources="resources" :client="client" />
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import GridView from "./resources-partials/GridView";
import ListView from "./resources-partials/ListView";
import AddResource from "./resources-partials/AddResource";

export default {
  data() {
    return {
      client: null,
      resources: [],
      sortDirection: "desc",
      sorting: "created_at",
      search: "",
      resourceToShare: null,
      folderName: "",
      listView: false,
      submitted: false,
      folderOpen: null,
      loading: false,
    };
  },
  methods: {
    getFolderChildren(folder) {
      let folder_children = this.resources.filter((item) => {
        return item.resource.folder === folder.resource.id;
      });
      for (let i = 0; i < folder_children.length; i++) {
        if (folder_children[i].resource.model === "FolderResource") {
          folder_children = folder_children.concat(
            this.getFolderChildren(folder_children[i])
          );
        }
      }
      return folder_children;
    },
    unshareFolder(folder) {
      var confirmed = confirm(
        "Are you sure you wish to revoke this resource folder and all files within it from the client?"
      );
      if (confirmed) {
        let folder_children = this.getFolderChildren(folder);
        folder_children.push(folder);

        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/resources/revoke-share-folder",
            {
              client_resource_list: folder_children,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchClientResources();
          });
      }
    },
    unshareResource(resource) {
      var confirmed = confirm(
        "Are you sure you wish to revoke this resource from the client?"
      );
      if (confirmed) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/resources/revoke-share", {
            client_resource_id: resource.id,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchClientResources();
          });
      }
    },
    addResource() {
      this.$refs.addResource.openModal();
    },
    updateResource(resource) {
      this.$axios
        .put(
          process.env.VUE_APP_API_URL +
            "/resources/api/" +
            resource.resource.id,
          {
            title: resource.resource.title,
            description: resource.resource.description,
          }
        )
        .then((data) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    moveResources(targetId, resources) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/resources/move-client-resources",
          {
            target_folder_id: targetId,
            resources: resources,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    folderDragEnter(event) {
      $("#parentDrop").addClass("bg-primary");
    },
    folderDragLeave(event) {
      $("#parentDrop").removeClass("bg-primary");
    },
    folderDrop(event) {
      $("#parentDrop").removeClass("bg-primary");

      const evtData = JSON.parse(event.dataTransfer.getData("text"));
      console.log(evtData.id);

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/resources/move-client-resource", {
          target_folder_id: this.folderOpen.folder,
          source_id: evtData.id,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchClientResources();
        });
    },
    upLevel() {
      this.$nextTick(() => {
        console.log(this.folderOpen);
        if (this.folderOpen.client_folder) {
          this.folderOpen = this.resources.find(
            (res) => res.id === this.folderOpen.client_folder
          );
        } else {
          this.folderOpen = null;
        }
      });
    },
    viewResource(resource) {
      window.location.href =
        "/clients/" + this.client.id + "/client-resource/" + resource.id;
    },
    sendResource(resource) {
      let clients = [];
      clients.push(this.client);
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/resources/share", {
          clients: clients,
          resource_id: resource.id,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchClientResources();
        });
    },
    openFolder(folder) {
      this.folderOpen = folder;
    },
    createFolder() {
      this.submitted = true;
      if (this.folderName) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/resources/api", {
            title: this.folderName,
            description: "This is a folder",
            type: "folder",
            visible_to_clinic: true,
            clinic_id: this.client.clinic_id,
            folder: this.folderOpen ? this.folderOpen.id : 0,
          })
          .then(({ data }) => {
            this.sendResource(data.resource);
            console.log(data.resource);
            this.submitted = false;
            this.folderName = "";
          });
      }
    },
    title_compare(a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    },
    sortBy(data) {
      let vm = this;
      if (vm.sorting === "created_at") {
        data.sort(function (a, b) {
          return b.created_at - a.created_at;
        });
      } else if (vm.sorting === "name") {
        data.sort(this.title_compare);
      }

      if (vm.sortDirection === "desc") {
        data.reverse();
      }

      return data;
    },
    clearSearch() {
      this.search = "";
    },
    searchResources(data) {
      if (this.search && this.search.length > 0) {
        return data.filter((item) => {
          return (
            item.resource.title
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          );
        });
      }
      return data;
    },
    shareResource(resource) {
      this.resourceToShare = resource;
    },
    fetchClientResources() {
      this.loading = true;
      var vm = this;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/resources"
        )
        .then(({ data }) => {
          vm.resources = data;
          this.loading = false;
        });
    },
    async fetchClientData() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  filters: {
    formatLabel(str) {
      if (!str) {
        return "Sorting";
      } else {
        return str.replace(/_/g, " ");
      }
    },
    truncate(text) {
      if (text != null) {
        if (text.length > 250) {
          return text.substring(0, 250) + "...";
        } else if (text.length == 0) {
          return "No description provided";
        } else {
          return text;
        }
      } else {
        return "No description provided";
      }
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();
    this.fetchClientResources();
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    currentFolders() {
      if (this.folderOpen) {
        const vm = this;
        return this.folderResources.filter(function (el) {
          return el.client_folder === vm.folderOpen.id;
        });
      } else {
        return this.folderResources.filter(function (el) {
          return !el.client_folder;
        });
      }
    },
    currentResources() {
      if (!this.folderOpen) {
        return this.fileResources.filter((res) => !res.client_folder);
      } else {
        return this.fileResources.filter(
          (res) => res.client_folder === this.folderOpen.id
        );
      }
    },
    folderResources: function () {
      return this.sortBy(
        this.searchResources(
          this.resources.filter(function (el) {
            return el.resource?.model === "FolderResource";
          })
        )
      );
    },
    fileResources: function () {
      return this.sortBy(
        this.searchResources(
          this.resources.filter(function (el) {
            return el.resource?.model !== "FolderResource";
          })
        )
      );
    },
  },
  components: {
    GridView,
    ListView,
    AddResource,
  },
};
</script>

<style>
</style>
