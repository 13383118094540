<template>
  <div class="file-card cursor-pointer rounded mb-3">
    <div
      @click="viewResource(resource)"
      draggable="true"
      @dragstart="resourceDragStart"
      class="pt-2 rounded"
    >
      <i class="far fa-4x text-primary" :class="fileIcon"></i>
      <p v-if="!editThisResource" class="mt-1">{{ resource.resource.title }}</p>
      <input
        type="text"
        class="form-control my-2"
        v-if="editThisResource"
        v-model="resource.resource.title"
        @change="renameResource(resource)"
        @click.stop
      />
    </div>
    <div class="row text-center">
      <span class="mx-auto">
        <i
          class="far fa-edit text-secondary cursor-pointer"
          v-tooltip.bottom="'Rename Resource'"
          @click="editThisResource = !editThisResource"
        ></i>
        <i
          class="far fa-share-square text-danger cursor-pointer mx-1"
          v-tooltip.bottom="
            'Remove share from client portal (this does not delete the file for you)'
          "
          @click.stop="unshareResource(resource)"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
import getClassNameForExtension from "font-awesome-filetypes";
export default {
  props: ["resource", "compact"],
  data() {
    return {
      editThisResource: false,
    };
  },
  methods: {
    unshareResource(resource) {
      this.$emit("unshareResource", resource);
    },
    renameResource(resource) {
      this.$emit("renameResource", resource);
      this.editThisResource = false;
    },
    viewResource(resource) {
      this.$emit("viewResource", resource);
    },
    resourceDragStart(event) {
      event.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "file",
          id: this.resource.id,
          folder_id: this.resource.folder,
        })
      );
    },
  },
  computed: {
    fileIcon() {
      var icon = "fa-file";
      if (this.resource.resource.title) {
        const extension = this.resource.resource.title.split(".").pop();
        return getClassNameForExtension(extension);
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  mounted() {},
  components: {},
};
</script>

<style>
.client-upload-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.file-card:hover {
  background-color: #f4f6f6 !important;
}
</style>
