<template>
  <div
    class="folder-card mb-2 rounded"
    :id="'folder-card-' + this.folder.resource.id"
  >
    <div class="cursor-pointer">
      <div
        @click="openFolder(folder)"
        draggable="true"
        class="pt-2 rounded"
        @dragstart="folderDragStart"
      >
        <i
          class="far fa-folder fa-4x text-info"
          @dragover.prevent
          @drop.stop="folderDrop($event)"
          @dragenter.stop="folderDragEnter($event)"
          @dragleave.stop="folderDragLeave($event)"
        ></i>
        <p v-if="!editThisFolder" class="mt-1">{{ folder.resource.title }}</p>
        <input
          type="text"
          class="form-control my-2"
          v-if="editThisFolder"
          v-model="folder.resource.title"
          @change="renameFolder(folder)"
          @click.stop
          autofocus
        />
      </div>
    </div>
    <div class="row text-center">
      <span class="mx-auto">
        <i
          class="far fa-edit text-secondary cursor-pointer"
          v-tooltip.bottom="'Rename Folder'"
          @click="editThisFolder = !editThisFolder"
        ></i>

        <i
          class="far fa-share-square text-danger cursor-pointer mx-1"
          v-tooltip.bottom="
            'Remove folder share from client portal and all files within it'
          "
          @click.stop="unshareFolder(folder)"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["folder", "resources"],
  data() {
    return {
      editThisFolder: false,
    };
  },
  methods: {
    unshareFolder(folder) {
      this.$emit("unshareFolder", folder);
    },
    shareFolder(folder) {
      this.$emit("shareFolder", folder);
    },
    deleteFolder(folder) {
      this.$emit("deleteFolder", folder);
    },
    renameFolder(folder) {
      this.$emit("renameResource", folder);
      this.editThisFolder = false;
    },
    openFolder(folder) {
      this.$emit("openFolder", folder);
    },
    folderDragStart(event) {
      event.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "folder",
          id: this.folder.id,
          parent_id: this.folder.folder,
        })
      );
    },
    folderDragEnter(event) {
      $("#folder-card-" + this.folder.resource.id).addClass("bg-primary");
    },
    folderDragLeave(event) {
      $("#folder-card-" + this.folder.resource.id).removeClass("bg-primary");
    },
    folderDrop(event) {
      $("#folder-card-" + this.folder.resource.id).removeClass("bg-primary");
      const evtData = JSON.parse(event.dataTransfer.getData("text"));
      if (this.folder.resource.id !== evtData.id) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/resources/move-client-resource", {
            target_folder_id: this.folder.id,
            source_id: evtData.id,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$emit("refreshed");
          });
      }
    },
  },
  computed: {
    toolTipText() {
      var ret = "";
      if (this.file.uploaded_by_client) {
        ret = ret + "Uploaded by client <br />";
      }
      ret =
        ret + "Uploaded " + moment(this.file.created_at).format("L") + "<br />";
      if (!this.file.uploaded_by_client && this.file.share) {
        ret =
          ret +
          "Shared " +
          moment(this.file.share.created_at).format("L") +
          "<br />";
      }
      return ret;
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  mounted() {},
  components: {},
};
</script>

<style>
.folder-card:hover {
  background-color: #f4f6f6 !important;
}
</style>
