<template>
  <div>
    <template v-if="loading">
      <busy />
    </template>
    <template v-else>
      <div class="row mb-3" v-if="folders && folders.length > 0">
        <div class="col-3 text-center" v-for="folder in folders">
          <folder-card
            :folder="folder"
            :resources="resources"
            @openFolder="openFolder"
            @renameResource="renameResource"
            @unshareFolder="unshareFolder"
            @refreshed="refreshed"
          ></folder-card>
        </div>
      </div>
      <div class="row mb-3" v-if="resources && resources.length > 0">
        <div class="col-3 text-center" v-for="resource in resources">
          <resource-card
            :resource="resource"
            @viewResource="viewResource"
            @renameResource="renameResource"
            @unshareResource="unshareResource"
            @refreshed="refreshed"
          ></resource-card>
        </div>
      </div>
      <div
        class="row"
        v-if="
          (!resources || resources.length === 0) &&
          (!folders || folders.length === 0)
        "
      >
        <div class="col text-center">
          <p><i class="far fa-info-circle fa-4x text-primary"></i></p>
          <p class="mb-0">
            No resources found. Upload a file or create a new folder
            above.
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ResourceCard from "./ResourceCard";
import FolderCard from "./FolderCard";
export default {
  props: ["folders", "resources", "loading"],
  data() {
    return {
    };
  },
  methods: {
    unshareFolder(folder) {
      this.$emit("unshareFolder", folder);
    },
    unshareResource(resource) {
      this.$emit("unshareResource", resource);
    },
    renameResource(resource) {
      this.$emit("renameResource", resource);
    },
    openFolder(folder) {
      this.$emit("openFolder", folder);
    },
    viewResource(resource) {
      this.$emit("viewResource", resource);
    },
    refreshed() {
      this.$emit("refreshed");
    },
  },
  computed: {},
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  mounted() {},
  components: {
    FolderCard,
    ResourceCard,
  },
};
</script>

<style>
</style>
