<template>
  <div
    class="modal fade top-z"
    id="addModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalTitle"
    aria-hidden="true"
    data-backdrop="false"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title client-tab-heading" id="modalTitle">
            Share Resources
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <input
                    class="form-control form-control-sm mb-2"
                    type="text"
                    placeholder="Search resources.."
                    v-model="searchQuery"
                  />
                  <div class="table-responsive resTable">
                    <table class="table table-sm table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Resource</th>
                          <th>Type</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="r in filteredResources"
                          :key="r.id"
                          :class="isAttached(r) ? 'bg-info text-white' : ''"
                        >
                          <td>#{{ r.id }}</td>
                          <td>{{ r.title }}</td>
                          <td>{{ r.model.replace("Resource", "") }}</td>
                          <td>
                            <button
                              class="btn btn-xs me-1"
                              v-if="!isAttached(r)"
                            >
                              <i
                                class="fa fa-plus"
                                @click="selectResource(r)"
                                v-tooltip:bottom="'Add Resource'"
                              ></i>
                            </button>
                            <button class="btn btn-xs me-1" v-else>
                              <i
                                class="fa fa-minus"
                                @click="selectResource(r)"
                                v-tooltip:bottom="'Add Resource'"
                              ></i>
                            </button>
                            <button
                              class="btn btn-xs me-1"
                              @click="viewResource(r)"
                              v-if="!(r.model === 'FolderResource')"
                            >
                              <i
                                class="fas fa-eye"
                                v-tooltip:bottom="'Preview Resource'"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary" @click="closeModal()">
            <i class="fas fa-times me-1"></i> Cancel
          </button>
          <button class="btn btn-primary" @click="ShareSelection()" :disabled="loading">
            <i class="far fa-share me-1"></i> Share
            <span v-if="ShareResources && ShareResources.length > 0">
              ( {{ ShareResources.length }} )
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ["client", "resources"],
  data() {
    return {
      AllResources: [],
      AvailableResources: [],
      ShareResources: [],
      searchQuery: "",
      loading: false,
    };
  },
  methods: {
    ShareSelection() {
      if (this.ShareResources) {
        if (this.ShareResources.length) {
          this.loading = true;
          this.$axios
            .post(process.env.VUE_APP_API_URL + "/resources/share-multi", {
              clients: [this.client],
              resources: this.ShareResources,
            })
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.loading = false;
              this.closeModal();
              this.$EventBus.$emit("refreshed");
            });
        }
      }
    },
    isShared(r) {
      return this.resources.some((res) => res.resource_id === r.id);
    },
    isAttached(r) {
      return this.ShareResources.some((res) => res.id === r.id);
    },
    isFolderEmpty(data, r) {
      return !data.some((res) => res.folder === r.id);
    },
    addResource(r) {
      if (this.isAttached(r)) {
        return;
      }
      this.ShareResources.push(r);
      if (r.folder) {
        let folder = this.AllResources.find((item) => item.id === r.folder);
        if (!folder) {
          console.log("Folder not found");
          return;
        }
        this.addResource(folder);
      }
    },
    removeResource(r) {
      this.ShareResources = this.ShareResources.filter(
        (item) => item.id !== r.id
      );
      /*
      Remove parent folder if empty
      if (r.folder) {
        var folderResource = this.ShareResources.find((res) => res.id === r.folder);
        if (folderResource && this.isAttached(folderResource) && this.isFolderEmpty(this.ShareResources, folderResource)) {
          this.removeResource(folderResource);
        }
      }*/
    },
    addFolder(r) {
      let vm = this;
      this.AllResources.forEach((item) => {
        if (item.folder === r.id) {
          vm.addResource(item);
          if (item.model === "FolderResource") {
            vm.addFolder(item);
          }
        }
      });
    },
    removeFolder(r) {
      let vm = this;
      this.ShareResources.forEach((item) => {
        if (item.folder === r.id) {
          vm.removeResource(item);
          if (item.model === "FolderResource") {
            vm.removeFolder(item);
          }
        }
      });
      this.removeResource(r);
    },
    selectResource(r) {
      if (!this.isAttached(r)) {
        this.addResource(r);
        if (r.model === "FolderResource") {
          this.addFolder(r);
        }
      } else {
        this.removeResource(r);
        if (r.model === "FolderResource") {
          this.removeFolder(r);
        }
      }
    },
    viewResource(resource) {
      open(resource.resource.file, "_blank");
    },
    closeModal() {
      this.ShareResources = [];
      $("#addModal").modal("hide");
    },
    openModal() {
      $("#addModal").modal("show");
    },
    addFolderItems(data, folderId, depth) {
      data.forEach((item) => {
        if (item.folder === folderId) {
          for (let i = 0; i < depth; i++) {
            item.title = " - " + item.title;
          }
          if (item.model !== "FolderResource" && !this.isShared(item)) {
            this.AvailableResources.push(item);
          } else if (!this.isFolderEmpty(this.AllResources, item)) {
            if (!this.isShared(item)) {
              this.AvailableResources.push(item);
            }
            this.addFolderItems(data, item.id, depth + 1);
          }
        }
      });
    },
  },
  mounted() {
    this.$axios
      .get(process.env.VUE_APP_API_URL + "/resources/api")
      .then(({ data }) => {
        this.AllResources = data;
        this.AvailableResources = []; //data.filter((item) => item.folder === 0);
        let vm = this;
        this.AllResources.forEach((item) => {
          if (item.folder === 0) {
            if (item.model !== "FolderResource" && !this.isShared(item)) {
              vm.AvailableResources.push(item);
            } else if (
              !vm.isFolderEmpty(vm.AllResources, item) &&
              !this.isShared(item)
            ) {
              vm.AvailableResources.push(item);
              vm.addFolderItems(data, item.id, 1);
            }
          }
        });
      });
  },
  computed: {
    filteredResources() {
      return this.AvailableResources.filter((r) => {
        return r.title.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.top-z {
  z-index: 9999;
}

.resTable {
  font-size: 12px;
  max-height: 400px;
  display: inline-block;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #03006b white;
}
/* Works on Chrome/Edge/Safari */
.resTable::-webkit-scrollbar {
  width: 8px;
}
.resTable::-webkit-scrollbar-track {
  background-image: white;
}
.resTable::-webkit-scrollbar-thumb {
  background-color: #413dc2;
  border-radius: 20px;
  border: 3px solid #03006b;
}
</style>
